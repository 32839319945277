import moment from 'moment';

const isBlackFriday = () => {
  const startDate = moment('2024-11-25T00:00:00');
  const endDate = moment('2024-12-03T23:59:59');
  if (moment().isBetween(startDate, endDate)) return true;
  return false;
};

export default isBlackFriday;
