import * as CONSTANTS from 'constants/styles';

const blackFridayBannerHeight = (isBlackFriday) => (isBlackFriday ? 50 : 0);
export const getEmptyContentHeight = (showBar) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_HEIGHT}px)`;
export const getEmptyBaseTableHeight = (showBar, hasImport = true, isBlackFriday) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 12 + blackFridayBannerHeight(isBlackFriday)}px) ${hasImport ? '!important' : ''}`;
export const getBaseTableHeight = (showBar, isBlackFriday) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.PAGINATION_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 18 + blackFridayBannerHeight(isBlackFriday)}px) !important`;
export const getInsightTableHeight = (showBar, isBlackFriday) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.TABS_HEADER_HEIGHT + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.PAGINATION_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 19 + blackFridayBannerHeight(isBlackFriday)}px) !important`;
export const getEmptyInsightTableHeight = (showBar, hasImport = true, isBlackFriday) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.TABS_HEADER_HEIGHT + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 15 + blackFridayBannerHeight(isBlackFriday)}px) ${hasImport ? '!important' : ''}`;
export const getListTableHeight = (showBar, isBlackFriday) => `calc(100vh - ${showBar ? 235 : 200}px + ${CONSTANTS.PAGINATION_HEIGHT + blackFridayBannerHeight(isBlackFriday)}px)`;
export const getSequenceLeadHeight = (showBar, offset = 0) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.PAGINATION_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 200 + offset}px) !important`;
export const getSequencesTableHeight = (showBar) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.PAGINATION_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 20}px) !important`;
export const getImportExportHeight = (showBar) => `calc(100vh - ${(showBar ? CONSTANTS.CREDIT_BAR_HEIGHT : 0) + CONSTANTS.HEADER_TABLE_HEIGHT + CONSTANTS.ROW_TABLE_HEIGHT + CONSTANTS.PAGINATION_HEIGHT + CONSTANTS.TOP_BAR_HEIGHT + 180}px) !important`;
