import { headShake } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { LinkUnderline } from 'components/commonStyles';

import { Icon } from 'components/common';
import { MOBILE_MAX_WIDTH } from 'constants/styles';
import { getBaseTableHeight } from 'helpers/table/getTableHeight';
import * as CONSTANTS from '../../../constants/styles';

const headShakeAnimation = keyframes`${headShake}`;

export const PaginationContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 30px;
  li:hover {
    background-color: rgba(24, 144, 255, 0.1);
    color: ${CONSTANTS.COLORS.PRIMARY};
  }

  li {
    min-width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: ${CONSTANTS.COLORS.GREY_G5};
    border-color: transparent;
  }
  
  li a {
    color: ${CONSTANTS.COLORS.GREY_G1};
  }

  .ant-pagination-item-active {
    background-color: ${CONSTANTS.COLORS.PRIMARY} !important;
  }

  .ant-pagination-item-active a{
  color: ${CONSTANTS.COLORS.WHITE} !important;
  }

  .ant-pagination-item-link {
    width: 32px;
    height: 32px;
    border-radius: 6px !important;
    background-color: ${CONSTANTS.COLORS.GREY_G5} !important;
    border-color: transparent !important;
  }

  .ant-pagination-next, .ant-pagination-prev {
    &:hover {
      background: none;
    }

    a {
      width: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'AvenirNext';
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      margin: 0 20px;
    }
  }

  .ant-pagination-next {
    a {
      &:before {
        content: 'Next';
      }
    }
  }

  .ant-pagination-prev {
    a {
      &:after {
        content: 'Prev';
      }
    }
  }

  .ant-pagination-options {
    &:hover {
      background: none;
    }

    .ant-select-selection {
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: ${CONSTANTS.COLORS.PRIMARY};
    }
  }
`;

export const TableContainer = styled.div`
  @media (min-width: ${CONSTANTS.MOBILE_MAX_WIDTH + 1}px) {
    margin-right: ${(props) => (props.desktopMarginRight ? props.desktopMarginRight : 0)}px;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: hidden;
  }

  ${(props) => props.hasSortRestriction && `
    .ant-table-row-cell-break-word:not(:first-child) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .ant-table-header-column {
        width: 100%;
        cursor: not-allowed;
        height: 100%;
        display: flex;
        align-items: center;

        div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          .ant-table-column-title {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  `}

  .ant-table-thead {
    th {
      &:not(.ant-table-selection-column) {
        padding-left: 15px !important;
      }
    }
  }

  .ant-table.ant-table-bordered .ant-table-title  {
    padding-top: 12px;
    padding-bottom: 11px;
    top: 0;
  }
  .ant-table-placeholder {
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    height: ${(props) => getBaseTableHeight(props.showBar, props.isBlackFriday)};
  }
  .ant-table th.ant-table-selection-column {
    width: 50px;
  }

  .ant-table-scroll {
    .ant-table-header {
      margin-bottom: ${(props) => (props.marginBottom)}px !important;
      @media (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: -16px !important;
      }
    }
  }

  .ant-table-content:after, .ant-table-fixed-left:after, .ant-table-fixed-right:after {
    content: '';
    width: 100%;
    height: 15px;
    left: 0;
    bottom: 0;
    background-image: -webkit-linear-gradient(top,rgba(255,255,255,0) 0,#fff 100%);
    background-image: linear-gradient(top,rgba(255,255,255,0) 0,#fff 100%);
    background-image: -moz-linear-gradient(top,rgba(255,255,255,0) 0,#fff 100%);
    background-image: -o-linear-gradient(top,rgba(255,255,255,0) 0,#fff 100%);
    background-image: --linear-gradient(top,rgba(255,255,255,0) 0,#fff 100%);
  }

  .ant-table-wrapper {
    width: 100%;
    overflow-y: hidden;

    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      width: auto;
    }
  }

  .ant-table-body, .ant-table-body-inner {
    max-height: ${(props) => getBaseTableHeight(props.showBar, props.isBlackFriday)};
  }

  table {
    ${(props) => props.isDatabaseDemo && `
      .ant-table-tbody {
        .ant-table-row {
          opacity: 0.4;
        }
        .ant-table-row:nth-child(-n+5) {
          opacity: 1;
        }
      }
    `}

    .ant-table-thead > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4} !important;

      @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
        background-color: ${CONSTANTS.COLORS.WHITE} !important;
      }
    }

    tr.ant-table-row-selected td {
      background-color: ${CONSTANTS.COLORS.WHITE} !important;
    }
  }

  border: 1px solid ${CONSTANTS.COLORS.GREY_G4};
  background-color: ${CONSTANTS.COLORS.WHITE} !important;;
  th {
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
  }
  tr {
    transition: none;

    td {
      transition: none;
      max-width: ${CONSTANTS.DEFAULT_COLUMN_WIDTH}px;
      padding: 0 0 0 15px !important;
      height: ${CONSTANTS.ROW_TABLE_HEIGHT}px !important;
    }

    td:first-child {
      padding: 0 !important;
    }
  }

  .ant-table-scroll {
    td, th {
      &:not(:last-child) {
        border-right: 1px solid ${CONSTANTS.COLORS.GREY_G7};
      }
    }
  }

  .ant-table-fixed-left {
    box-shadow: none;

    td, th {
      border-right: 1px solid ${CONSTANTS.COLORS.GREY_G7};
    }
  }

  .ant-table-fixed-right {
    box-shadow: none;
  }

  .ant-table-scroll-position-left, .ant-table-scroll-position-middle {
    .ant-table-fixed-right {      
      td, th {
        border-left: 1px solid ${CONSTANTS.COLORS.GREY_G7};
      }
    }
  }
  
  td {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.65);
    transition: none;
  }

  .ant-table-header-column {
    height: 100%;
    .ant-table-column-sorters {
      height: 100%;
    }

    & > div {
      height: 100%;

      & > .ant-table-column-title {
        height: 100%;
      }
    }
  }

  th:not(:first-of-type) {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 600;

    .ant-table-header-column {
      .ant-table-column-sorter-inner {
        margin-top: -5px;
      }
    }
    
    ${(props) => !props.defaultColStyle && `
     :hover {
      background-color: ${CONSTANTS.COLORS.GREY_G6_OPACITY} !important;
      ${props.icDrag && `
        :before {
          content: '';
          position: absolute;
          left: 5px;
          top: 10px;
          display: inline-block;
          background-image: url(${props.icDrag});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 6px;
          height: 20px;
          cursor: move;
        }
      `}
      }
    `}
  }

  ${(props) => props.allBoldHeaders && `
    th {
      font-weight: 600 !important;
    }
  `}
  
  ${(props) => props.noDraggableCols && `
    th:nth-child(-n+${props.noDraggableCols}) {
      :hover:before {
        content: none;
      }
    }
  `}
  
  .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
    content: none;
  }
  
  th:nth-child(2) {
    .ant-table-header-column {
      margin: 0;
    }
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .rowStyle {
    width: 1150px;
    height: ${CONSTANTS.ROW_TABLE_HEIGHT}px !important;
    border-radius: 4px;
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
  }

  .rowStyleWide {
    width: 1150px;
    border-radius: 4px;
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
    height: ${CONSTANTS.ROW_TABLE_HEIGHT + 20}px !important;
  }

  .rowStyleMiddleWide {
    width: 1150px;
    border-radius: 4px;
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
    height: ${CONSTANTS.ROW_TABLE_HEIGHT + 10}px !important;
  }
    
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }

  .ant-table-row {
    .custom-row-margin {
      margin-left: 15px;
    }

    @media (max-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
      .custom-row-name {
        max-width: calc(100% - 40px);
      }
    }

    &:hover {
      cursor: pointer;

      .mobile_more_button_container {
        background-color: #f2f6ff !important;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;

        .mobile_more_button {
          fill: ${CONSTANTS.COLORS.PRIMARY};
        }
      }

      .preview_button {
        @media (min-width: ${CONSTANTS.MOBILE_MAX_WIDTH}px) {
          position: absolute;
          width: 80px;
          height: 32px;
          border-radius: 4px;
          border: solid 1px ${CONSTANTS.COLORS.GREY_G4};
          background-color: ${CONSTANTS.COLORS.WHITE};
          display: block;
          margin-right: 10px;
        }
      }

      .more_button {
        @media (min-width: 640px) {
          top: -7px;
          position: absolute;
          width: 80px;
          height: 32px;
          border-radius: 4px;
          border: solid 1px #dfe2e5;
          background-color: ${CONSTANTS.COLORS.WHITE};
          display: flex;
          align-items: center;
          justify-content: center;
          word-break: keep-all;
        }
      }
    }
  }

  .material-icons {
    display: none;
  }

  .ant-table-row:hover {
    .preview {
      display: block;
    }

    td:hover {
      .material-icons {
        display: block;
      }
    }
  }

  .ant-table-row-hover {
    .icons {
      display: flex;
    }

    .ant-table-row-cell-break-word, .ant-table-selection-column {
      background-color: ${CONSTANTS.COLORS.PRIMARY_P4} !important;
    }
    .linkedin {
      padding: 3px;
      display: block;
    }

    .button-actions {
      display: flex;
      align-items: center;
    }
  }

  .ant-table-fixed {
    line-height: 1.045;
  }

  .ant-table-thead {
    border-radius: 4px;
    background-color: ${CONSTANTS.COLORS.WHITE} !important;
    
    > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
      margin-left: 5px;
    }
  }
  
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${CONSTANTS.COLORS.PRIMARY};
    border-color: ${CONSTANTS.COLORS.PRIMARY};
    
    ::after {
      height: 2px;
      background-color: ${CONSTANTS.COLORS.WHITE};
    }
  }

  .ant-table-without-column-header {
    .ant-table-title {
      overflow-x: auto;
    }
  }
  
  .ant-table-title {
    display: flex;
    height: ${CONSTANTS.HEADER_TABLE_HEIGHT}px;
    top: 0;
    padding: 0;
    padding-left: 9px;
    border-bottom: 1px solid ${CONSTANTS.COLORS.GREY_G4};

    .material-icons {
      display: block;
    }
  }
  
  .ant-table-thead > tr > th.ant-table-column-sort {
    background: ${CONSTANTS.COLORS.GREY_DISABLED} !important;
  }

  ${(props) => (props.noSortColumns && `
    th:not(:first-of-type) {
      cursor: default;
      
      &:hover {
        background-color: ${CONSTANTS.COLORS.WHITE} !important;
      }
    }
  `)}
`;

export const BaseTableWrapper = styled.div`
  @media (min-width: ${CONSTANTS.MOBILE_MAX_WIDTH + 1}px) {
    margin-right: 15px;
  }

  .ant-table-fixed-left {
    width: ${(props) => (props.fixedLeftWidth ? props.fixedLeftWidth + 50 : CONSTANTS.DEFAULT_COLUMN_WIDTH + 50)}px;
  }

  .ant-table-title + .ant-table-content > .ant-table-fixed-left {
    margin-top: -1px;
  }
  
  ${(props) => props.selected && `
    .ant-table-fixed-left, .ant-table-fixed-right {
      top: 1px;
    }
  `}
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  ${(props) => props.customWidth && `
    max-width: ${props.customWidth};
  `}

  .person_icon {
    min-width: 32px;
  }

  .company_name:hover, .contact_name:hover {
    color: ${CONSTANTS.COLORS.PRIMARY};
    text-decoration: underline;
  }

  .preview_button {
    position: absolute;
    display: none;
    background-color: #eaf0f6;
    border-color: #cbd6e2;
    color: #506e91;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.65) !important;
    border-style: solid;
    border-width: 1px;
    transition: all .15s ease-out;
    padding: 1px 6px;
    font-size: 12px;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: #f5f8fa;
      color: #506e91;
    }
  }

  .preview {
    display: none;
    margin-right: 10px;
    margin-left: auto;
  }

  .linkedin {
    margin-left: auto;
    margin-right: 15px;
    display: none;
    right: 0;

    svg {
      width: 17px;
      height: 17px;
      fill: ${CONSTANTS.COLORS.GREY_G3};

      &:hover {
        fill: ${CONSTANTS.COLORS.BLUE_B3};
      }
    }
  }

  .linkedin_container {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  .more_button {
    display: none;
    border-color: #cbd6e2;
    color: #506e91;
    right: 0;
    cursor: pointer;

    top: -7px;
    position: absolute;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #dfe2e5;
    background-color: ${CONSTANTS.COLORS.WHITE};
    align-items: center;
    justify-content: center;

    border-style: solid;
    border-width: 1px;
    font-size: 12px;
    word-break: keep-all;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: #f5f8fa;
      color: #506e91;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${((props) => props.visible && `
    .more_button {
        @media (min-width: 640px) {
          top: -5px;
          position: absolute;
          width: 80px;
          height: 32px;
          border-radius: 4px;
          border: solid 1px #dfe2e5;
          background-color: ${CONSTANTS.COLORS.WHITE};
          display: block;
          word-break: keep-all;
        }
      }
  `)}
`;

export const CorporateEmailContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 15px;
    box-sizing: border-box;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .animated {
      animation: 1s ${headShakeAnimation};
    }

    .material-icons {
      min-width: 18px;
    }

    &:hover {
      .material-icons {
        display: block;
      }
    }
`;
export const EmailStatusIconContainer = styled.div`
  margin-right: 5px;
`;

export const EmailCopyIcon = styled(Icon).attrs(() => ({
  type: 'ic-content-copy',
  size: 'smallest',
  fillOnHover: 'primary',
}))`
  cursor: pointer;
  margin-left: auto;
  display: none;
`;

export const CompanyLink = styled.span`
cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ExternalLink = styled(LinkUnderline)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .domain-icon {
    display: none;
  }

  &:hover {
    .domain-icon {
      display: flex;
    }
  }
`;

export const FullContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
