import { GP_GROUP } from 'data/types/user.types';
import isBlackFriday from 'helpers/isBlackFriday';
import { getCookie, setCookie } from 'hooks/useSignUpQuery';

export const updateGpGroup = ({
  groupNumber,
  randomGroupNumbers = [GP_GROUP.DEFAULT, GP_GROUP.NEW_PRICING_3],
  forceUpdate,
}) => {
  if (process.env.REACT_APP_DISABLE_GROUPS) return 0;
  let gpGroup = getCookie('gp_group');
  const blackFriday = isBlackFriday();
  if ((!gpGroup || forceUpdate) && !blackFriday) {
    if (randomGroupNumbers) {
      const randomIndex = new Date().getUTCSeconds() % randomGroupNumbers.length;
      gpGroup = randomGroupNumbers[randomIndex];
    } if (groupNumber) {
      gpGroup = groupNumber;
    }
    const threeMonths = 7776000;
    const options = {
      domain: 'getprospect.com',
      'max-age': threeMonths,
    };
    setCookie('gp_group', gpGroup, options);
  }
  return +gpGroup;
};
